import axios from "axios";
import { useEffect, useState } from "react";
import logo from './images/SunriseLogo.jpg';
import { useNavigate } from "react-router";
function Exam()
{
    
    const [count, setCount] = useState(30);
    let [arr,setArr]=useState([]);
    let [qno,setQno]=useState(0);
    let [x1,setX1]=useState('block');
    let [x2,setX2]=useState('none');
    let [x3,setX3]=useState('none');
    let [x4,setX4]=useState('none');
    let [userAns,setUserAns]=useState([]);
    let [QAns,setQAns]=useState([]);
    let nav=useNavigate();
    function timer()
    {
        setCount(count - 1); 
        if(count==0)
        {
            goNext();            
        }
    }
    async function loadQuestions()
    {
        let obj={key:"query@sci.ind.in"};
        let json=JSON.stringify(obj);
        await axios.post("https://api.sunriseindia.co.in/questions.php",json).then(reply=>
        {
            if(reply.status==200 && reply.data.status=="OK")
            {
                setArr(reply.data.questions);
                let qq=[];
                reply.data.questions.map((e,i)=>
                    {
                        qq[i]=e.ans;
                    });
                setQAns(qq);
                
            }
        });        
    }
    function showQuestion()
    {
        try{
        document.getElementById("ques").innerHTML=(qno+1)+") " +arr[qno].question.replaceAll("\n","<br/>");
        document.getElementById("op1").innerHTML=arr[qno].op1;
        document.getElementById("op2").innerHTML=arr[qno].op2;
        document.getElementById("op3").innerHTML=arr[qno].op3;
        document.getElementById("op4").innerHTML=arr[qno].op4;
        document.getElementById("r1").checked=false;
        document.getElementById("r2").checked=false;
        document.getElementById("r3").checked=false;
        document.getElementById("r4").checked=false;
        document.getElementById("r3").disabled=arr[qno].op3=="-";
        document.getElementById("r4").disabled=arr[qno].op3=="-"; 
        }catch(Ex)
        {

        }
    }
    function goNext()
    {
        setQno(qno+1);
        showQuestion();
        setCount(30);
        if(qno==59)
        {
            setX3('none');
            setX4('block');
        }
    }    
    function storeAnswer(event)
    {
        let ans=event.target.value;
        let arr=[...userAns];
        arr[qno-1]=ans;
        setUserAns([...arr]);        
    }
    function startPaper()
    {
        setX1('none');
        setX2('block');
        setX3('block');
        goNext();
         
    }
    function submitPaper()
    {
        let marks=0;
        for(let i=0;i<QAns.length;i++)
        {
            if(userAns[i] && userAns[i]==QAns[i])
            {
                marks++;
            }            
        }
        let obj={key:"query@sci.ind.in",marks:marks,id:window.id};
        let json=JSON.stringify(obj);
        axios.post("https://api.sunriseindia.co.in/saveresult.php",json).then(reply=>
            {
                if(reply.status==200)
                {
                    if(reply.data.status=="OK")
                    {
                        nav("/thanks");
                    }
                    else
                    {
                        window.alert("Contact Adminsitrator!!");
                    }
                }
            });
        
    }
    useEffect(()=>{
        loadQuestions();        
    },[]);
    
    useEffect(() => 
    {
        if(qno<=60)
        {
            const interval= setInterval(() => {
                timer();   
            }, 1000);        
            //Clearing the interval
            return () => clearInterval(interval);       
        }
        else if(count==60)
        {
            submitPaper();
        }
    }, [count]);    
    return(
        <div className="p-3 border shadow rounded-3">
            <div className="row">
                <div className="col-2"><img src={logo} className="w-100" style={{maxWidth:'80px'}} /></div>
                <div className="col-6"><b>{window.sname}</b></div>
                <div className="col-4 text-end"><div className="text-danger text-end fw-bold">Time: {count} Seconds</div></div>
            </div>            
            <hr/>
            <div className="alert alert-info fs-5" style={{display:x1}}>
                <ol>
                    <li>Please Read Question Properly before answering.</li>
                    <li>After 30 Second Next Question Appear Automatically.</li>
                    <li>There is NO option to GO BACK.</li>
                    <li>You can change answer.</li>
                    <li>You can also Click on NEXT Button for NEXT Question</li>
                    <li>Don't forget to Submit the Paper After Exam</li>
                    <li>BEST OF LUCK</li>
                    <li className="text-danger">READ THESE INSTRUCTIONS IN 30 SECONDS AND START THE PAPER, OTHERWISE YOU WILL LOST THE QUESTIONS</li>
                </ol>
                <input type="button" onClick={startPaper} value="Start Paper" className="btn btn-info" /> 
            </div>

            <div className="alert alert-danger fs-5"  style={{display:x2}}>
                <div className="m-3" id="ques"></div>
                <div className="m-3" >1) <input type="radio" onChange={storeAnswer} value="1" id="r1" name="r1" /> <span id="op1"></span> </div>
                <div className="m-3" >2) <input type="radio" onChange={storeAnswer} value="2" id="r2" name="r1" /> <span id="op2"></span> </div>
                <div className="m-3" >3) <input type="radio" onChange={storeAnswer} value="3" id="r3" name="r1" /> <span id="op3"></span> </div>
                <div className="m-3" >4) <input type="radio" onChange={storeAnswer} value="4" id="r4" name="r1" /> <span id="op4"></span> </div>
            </div>
            <hr/>            

            <input type="button" onClick={goNext} value="Next Question" className="btn btn-success" style={{display:x3}} />
            <input type="button" onClick={submitPaper} value="Submit" className="btn btn-success" style={{display:x4}} />            
            &nbsp;&nbsp;IMPORTANT: You can't go back
        </div>
    );
}
export default Exam;