import { Route, Routes } from "react-router";
import Register from "./register";
import Instructions from "./instructions";
import Exam from "./exam";
import Login from "./login";
import Thanks from "./thanks";

function App()
{
  return(
    <div>
        <Routes>
          <Route path="/" element={<Login />}/>

          <Route path="/instructions" element={<Instructions />}/>          
          <Route path="/exam" element={<Exam />}/>  
          <Route path="/thanks" element={<Thanks/>}/>        
        </Routes>
    </div>
  );
}
export default App;