import { useNavigate } from "react-router";

export default function Instructions()
{
    let nav=useNavigate();
    function goNext(event)
    {
        let x=window.confirm('Are you sure?');        
        if(x)
        {
            nav("/exam");
        }
    }
    return(
        <div className="row mt-3">
            <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-1 col-sm-1 col-12"></div>
            <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-10 col-sm-10 col-12">
                <div className="card">
                    <div className="card-header text-center fw-bold bg-danger text-white h4">Important Instructions</div>
                    <div className="card-body text-primary fw-bold">
                        <ol className="list">
                            <li>This Paper Contains total 60 Questions.</li>
                            <li>Each Question carry Equals Marks</li>
                            <li>10 Questions of Reasoning</li>
                            <li>10 Questions of Aptitude</li>
                            <li>10 Questions of General English</li>
                            <li>30 Questions of Basic Programming Skills</li>
                            <li className="text-danger">Each Questions have limited time for Answer (30 SECONDS).</li>
                            <li>IF YOU ARE UNABLE TO ANSWER THE QUESTION IN THE SPECIFIC TIME, YOU WILL GET NEXT QUESTION AUTOMATICALLY.</li>
                            <li>THERE IS NO OPTION FOR BACK/FORWARD</li>
                            <li className="text-danger">DO NOT USE BROWSER BACK/FORWARD BUTTON, IT WILL TERMINATE THE EXAM</li>
                            <li>YOU CAN SKIP THE QUESTION</li>
                           
                        </ol>
                        <input type="button" value="Start Exam" onClick={goNext} className="btn btn-primary btn-sm mt-3" />
                    </div>
                </div>                
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-1 col-sm-1 col-12"></div>
        </div>
    );
}