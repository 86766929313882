import { useEffect } from "react";
import exam from './images/exam.gif';
import logo from './images/SunriseLogo.jpg';
export default function Thanks()
{
    useEffect(()=>{
        window.id="";
        window.sname="";
    },[]);
    return(
        <div className="p-5">
            <img src={logo} width="100px" />
            <div className="p-3 border shadow rounded-5 p-5 text-center">
                <img src={exam} width="100px" /><br/>
                <h2>We will Share Your Result With Your College</h2>
                <a href="https://www.sci.ind.in">Visit our Website</a> or Close this Window
            </div>
            <div className="mt-5">
                <center>All Rights Reserved by Sunrise Technologies</center>
            </div>
        </div>
    );
}