import axios from "axios";
import { useNavigate } from "react-router";
import logo from './images/SunriseLogo.jpg';
window.id="";
window.sname="";
export default function Login()
{
    let nav=useNavigate();
    function doLogin(event)
    {   
        event.preventDefault();
        let s1=document.getElementById("txtEmail").value;
        let s2=document.getElementById("txtPass").value;
        let obj={ulogin:s1,upass:s2,key:"query@sci.ind.in"};
        let json=JSON.stringify(obj);
        axios.post("https://api.sunriseindia.co.in/login.php",json).then(reply=>
            {
                if(reply.status==200)
                {
                    if(reply.data.status=="OK")
                    {
                        window.id=reply.data.id;
                        window.sname=reply.data.sname;
                        nav("/instructions");
                    }
                    else
                    {
                        alert(reply.data.message);
                    }
                }
            });        
    }
    return(
        <div className="row mt-3">
            <div className="col-xxl-3 col-xl-3 col-lg-2 col-md-2 col-sm-1 col-12"></div>
            <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-8 col-sm-10 col-12">
            <center><img src={logo} /></center>
                <div className="card">
                    <div className="card-header text-center fw-bold">Student's Authentication</div>
                    <div className="card-body">                        
                        <form onSubmit={doLogin}>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" id="txtEmail" className="form-control  form-control-sm" required />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" id="txtPass" className="form-control  form-control-sm" required />
                        </div>                        
                        <input type="submit"  value="Login and Start Exam" className="btn btn-primary btn-sm mt-3" />
                        </form>
                    </div>
                </div>                  
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-2 col-md-2 col-sm-1 col-12"></div>
            <hr className="mt-5"/>
            <center>All Rights Reserved By <a href="https://www.sci.ind.in">Sunrise Technologies</a></center>              
        </div>
    );
}