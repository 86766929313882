const initialState={qans:[],userans:[]};
export function appReducer(state=initialState,action)
{
    if(action.type=='setQAns')
    {
        return {...state,qans:action.value};
    }
    else if(action.type=='setUserAns')
    {
        return {...state,userans:action.value};
    }
    else
        return state;
}